import { makeObservable, observable, flow, computed, action } from "mobx";
import { toast } from "sonner";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseRiskTypeSchema from "@/areas/settings/schemas/BrowseRiskTypeSchema";

export default class BrowseRiskTypesStore {
  loading = true;
  pagination = {};
  query = { search: "", page: 1 };
  riskTypes = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      riskTypes: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];

    list.push({
      id: "settings:types",
      label: "New Type",
      onClick: () => this.parentStore.createRiskTypeStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createRiskTypeStore.processing,
      disabled: this.parentStore.createRiskTypeStore.processing,
    });

    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(riskType) {
    const index = this.riskTypes.findIndex((u) => u.id === riskType.id);
    if (index !== -1) {
      this.riskTypes.splice(index, 1, riskType);
      this.riskTypes = [...this.riskTypes];
    }
  }

  *load(query, options) {
    const parseResult = BrowseRiskTypeSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.riskTypes = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forRiskTypes(), {
        params: query,
      });

      if (response.status == 200) {
        this.riskTypes = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading risk types", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
