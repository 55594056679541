import { makeObservable, observable, flow, computed, action } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { Edit, Add } from "@/components/icons/IconMap";
import { toast } from "sonner";

import BrowseModelsSchema from "@/areas/settings/schemas/BrowseModelsSchema";

export default class BrowseModelsStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  models = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      models: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];

    list.push({
      id: "inventory:addModel",
      label: " New Model",
      onClick: () => this.parentStore.createModelStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createModelStore.processing,
      disabled: this.parentStore.createModelStore.processing,
    });

    return list;
  }
  setSearchText(search) {
    this.query.search = search;
  }
  consume(model) {
    const index = this.models.findIndex((e) => e.id === model.id);
    if (index !== -1) {
      this.models.splice(index, 1, model);
      this.models = [...this.models];
    }
  }

  *load(query, options) {
    const parseResult = BrowseModelsSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.models = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forModels(), {
        params: query,
      });

      if (response.status == 200) {
        this.models = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading models", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
