import { makeObservable, observable, flow, action } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { toast } from "sonner";

import CreateSubDomainSchema from "@/areas/controls/schemas/CreateSubDomainSchema";

export default class CreateSubdomainStore {
  visible = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    const parseResult = CreateSubDomainSchema.safeParse(data);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      const response = yield api.post(ApiRoutes.forDomains(), data);
      this.visible = false;
      toast.success("Subdomain created successfully");
      return { success: true, data: response.data };
    } catch (e) {
      toast.error("Error while creating subdomain", {
        description: e.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    }
  }
}
