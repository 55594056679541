import { makeObservable, observable, flow, computed, action } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import RiskHeatmapSchema from "@/areas/risks/schemas/RiskHeatmapSchema";
import { Add } from "@/components/icons/IconMap";

export default class RiskHeatmapStore {
  loading = true;
  pagination = null;
  risks = [];
  summary = null;
  view = "residual";
  selected = null;

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      risks: observable,
      summary: observable,
      view: observable,
      selected: observable,
      actions: computed,
      load: flow,
      loadRisks: flow,
      setView: action,
      clearSelection: action,
    });

    this.parentStore = parentStore;
  }

  setView(view) {
    this.view = view ?? "residual";
  }

  get actions() {
    const list = [];

    list.push({
      id: "risks:report",
      label: "Report a Risk",
      onClick: () => this.parentStore.createRiskStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createRiskStore.processing,
      disabled: this.parentStore.createRiskStore.processing,
    });

    return list;
  }

  get groupSummary() {
    if (!this.summary) {
      return null;
    }

    const range = this.summary.maxCount - this.summary.minCount;

    return this.summary[this.view].map((s) => ({
      id: `h-${s.likelihood}-${s.impact}`,
      text: s.count,
      weight: Math.max(0.25, (s.count - this.summary.minCount) / range),
      score: { likelihood: s.likelihood, impact: s.impact },
    }));
  }

  clearSelection() {
    this.risks = [];
    this.selected = null;
    this.pagination = null;
  }

  *loadRisks(query, options) {
    const parseResult = RiskHeatmapSchema.safeParse(query);
    if (!parseResult.success) {
      console.error(parseResult.error);
      return parseResult;
    }
    try {
      this.loading = true;
      this.risks = [];

      if (isNaN(parseResult.data.selection.impact) || isNaN(parseResult.data.selection.likelihood)) {
        this.clearSelection();
        return { success: true, data: [] };
      } else {
        this.selected = parseResult.data.selection;
      }

      const searchQuery = { ...parseResult.data.query };
      searchQuery[`${parseResult.data.view}Likelihood`] = [this.selected.likelihood];
      searchQuery[`${parseResult.data.view}Impact`] = [this.selected.impact];

      const response = yield api.post(ApiRoutes.forRiskSearch(), {
        search: parseResult.data.search,
        page: parseResult.data.page,
        query: searchQuery,
        page: parseResult.data.page,
        sort: parseResult.data.sort,
      });

      this.risks = response.data.items;
      this.pagination = {
        page: response.data.page,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        pageSize: response.data.pageSize,
      };
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }

  *load(query, options) {
    const parseResult = RiskHeatmapSchema.safeParse(query);
    if (!parseResult.success) {
      console.error(parseResult.error);
      return parseResult;
    }

    try {
      this.loading = true;
      this.clearSelection();
      const response = yield api.post(ApiRoutes.forRiskHeatmapSummary(), query);
      this.summary = response.data;
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
