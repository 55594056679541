import { makeObservable, observable, flow, computed } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { Edit, Add } from "@/components/icons/IconMap";

import BrowseEntitiesSchema from "@/areas/settings/schemas/BrowseEntitiesSchema";
import UpdateModelStore from "@/areas/settings/stores/UpdateModelStore";

export default class BrowseEntitiesStore {
  loading = false;
  pagination = null;
  query = { search: "", page: 1 };
  entities = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      entities: observable,
      query: observable,

      load: flow,
    });

    this.parentStore = parentStore;
    this.updateModelStore = new UpdateModelStore(this);
  }

  get processing() {
    return this.loading || this.updateModelStore.processing;
  }
  get actions() {
    const list = [];

    // list.push({
    //     id: "inventory:models:update",
    //     label: "Edit",
    //     onClick: () => this.updateModelStore.show(),
    //     icon: Edit,
    //     location: "secondary",
    //     processing: this.updateModelStore.processing,
    //     disabled: this.processing || !this.updateModelStore.canUpdate,
    //   });

    // list.push({
    //   id: "inventory:models:update",
    //   label: "Edit",
    //   onClick: () => this.updateModelStore.show(),
    //   icon: Edit,
    //   location: "secondary",
    //   processing: this.updateModelStore.processing,
    //   disabled: this.processing || !this.updateModelStore.canUpdate,
    // });

    return list;
  }

  get processing() {
    return this.loading;
  }

  consume(entity) {
    const index = this.entities.findIndex((e) => e.id === entity.id);
    if (index !== -1) {
      this.entities.splice(index, 1, entity);
      this.entities = [...this.entities];
    }
  }

  *load(query) {
    const parseResult = BrowseEntitiesSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      const response = yield api.get(ApiRoutes.forModelItems(query.id), {
        params: query,
      });
      this.entities = response.data.items;
      this.pagination = {
        page: response.data.page,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        pageSize: response.data.pageSize,
      };
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
