import { makeObservable, observable, flow, computed, action } from "mobx";

import { toast } from "sonner";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseRegistriesSchema from "@/areas/settings/schemas/BrowseRegistriesSchema";

export default class BrowseRegistriesStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  registries = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      registries: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];

    list.push({
      id: "settings:registries",
      label: "New Registry",
      onClick: () => this.parentStore.createRegistryStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createRegistryStore.processing,
      disabled: this.parentStore.createRegistryStore.processing,
    });

    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(registry) {
    const index = this.registries.findIndex((u) => u.id === registry.id);
    if (index !== -1) {
      this.registries.splice(index, 1, registry);
      this.registries = [...this.registries];
    }
  }

  *load(query, options) {
    const parseResult = BrowseRegistriesSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.registries = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forControlRegistries(), {
        params: query,
      });
      if (response.status == 200) {
        this.registries = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading control registries", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
