import { makeObservable, observable, flow, computed } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { Add } from "@/components/icons/IconMap";

import BrowseDomainSchema from "@/areas/controls/schemas/BrowseDomainSchema";

export default class BrowseDomainStore {
  loading = false;
  pagination = null;
  domains = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      domains: observable,
      actions: computed,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get actions() {
    const list = [];

    list.push({
      id: "domain:addControl",
      label: "New domain",
      onClick: () => this.parentStore.createDomainStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createDomainStore.processing,
      disabled: this.parentStore.createDomainStore.processing,
    });

    return list;
  }

  consume(domain) {
    const index = this.domains.findIndex((e) => e.id === domain.id);
    if (index !== -1) {
      this.domains.splice(index, 1, domain);
      this.domains = [...this.domains];
    }
  }

  *load(query, options) {
    const parseResult = BrowseDomainSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }
    try {
      this.loading = true;
      const response = yield api.post(ApiRoutes.forSubdomains(), query);

      if (response.status == 200) {
        this.domains = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      }
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
