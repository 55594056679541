import { makeObservable, observable, flow, computed, action } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { Edit, Add } from "@/components/icons/IconMap";
import { toast } from "sonner";

import BrowseItemWatchlistSchema from "@/areas/inventory/schemas/BrowseItemWatchlistSchema";

export default class BrowseItemWatchlistStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  watchlistItems = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      watchlistItems: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];

    return list;
  }
  setSearchText(search) {
    this.query.search = search;
  }
  consume(watchlistItem) {
    const index = this.watchlistItems.findIndex((e) => e.id === watchlistItem.id);
    if (index !== -1) {
      this.watchlistItems.splice(index, 1, watchlistItem);
      this.watchlistItems = [...this.watchlistItems];
    }
  }

  *load(query, options) {
    const parseResult = BrowseItemWatchlistSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.watchlistItems = [];
      this.query = parseResult.data;

      const response = yield api.post(ApiRoutes.forItemSearch(), query);

      if (response.status == 200) {
        this.watchlistItems = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading items", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
