import { makeObservable, observable, flow, action } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import UpdateCurrentActivitySchema from "@/areas/controls/schemas/UpdateCurrentActivitySchema";

export default class UpdateCurrentActivityStore {
  visible = false;
  processing = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      processing: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    try {
      this.processing = true;

      const parseResult = UpdateCurrentActivitySchema.safeParse(data);
      if (!parseResult.success) {
        return parseResult;
      }

      // const response = yield api.put(ApiRoutes.forRisk(data.id), data);

      const response = {
        status: 202,
        data: data,
      };

      if (response.status == 202) {
        this.visible = false;
        toast.success("Current activity updated successfully");
        //   this.parentStore.load({ id: data.id });

        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected error code");
      }
    } catch (e) {
      toast.error("Error while updating current activity", {
        description: "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.processing = false;
    }
  }
}
