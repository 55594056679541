import { ApiRoutes } from "@/lib/routes";

import BrowseControlsStore from "@/areas/compliance/stores/BrowseControlsStore";
import BrowseDomainsStore from "@/areas/compliance/stores/BrowseDomainsStore";
import BrowseTargetsStore from "@/areas/compliance/stores/BrowseTargetsStore";
import BrowseDeploymentsStore from "@/areas/compliance/stores/BrowseDeploymentsStore";
import CreateControlStore from "@/areas/compliance/stores/CreateControlStore";
import CreateDomainStore from "@/areas/compliance/stores/CreateDomainStore";
import CreateTargetStore from "@/areas/compliance/stores/CreateTargetStore";
import BrowseActivitiesStore from "@/areas/compliance/stores/BrowseActivitiesStore";
import AddActivityStore from "@/areas/compliance/stores/AddActivityStore";
import BrowseTargetRolesStore from "@/areas/subscriptions/stores/BrowseTargetRolesStore";
import BrowseTargetFieldsStore from "@/areas/fields/stores/BrowseTargetFieldsStore";
import BrowseDeploymentTargetsStore from "@/areas/compliance/stores/BrowseDeploymentTargetsStore";
import CreateDeploymentTargetStore from "@/areas/compliance/stores/CreateDeploymentTargetStore";
import BrowseImplementationGroupsStore from "@/areas/compliance/stores/BrowseImplementationGroupsStore";
import CreateImplementationGroupStore from "@/areas/compliance/stores/CreateImplementationGroupStore";

export default class ComplianceRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseControlsStore = new BrowseControlsStore(this);
    this.browseDomainsStore = new BrowseDomainsStore(this);
    this.browseTargetsStore = new BrowseTargetsStore(this);
    this.browseDeploymentsStore = new BrowseDeploymentsStore(this);
    this.createDomainStore = new CreateDomainStore(this);
    this.createControlStore = new CreateControlStore(this);
    this.createTargetStore = new CreateTargetStore(this);

    this.browseActivitiesStore = new BrowseActivitiesStore(this);
    this.addActivityStore = new AddActivityStore(this);

    this.browseRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forComplianceRoles("control"));
    this.browseRegistryRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forComplianceRoles("registry"));
    this.browseActivityRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forComplianceRoles("activity"));

    this.browseFieldsStore = new BrowseTargetFieldsStore(this, ApiRoutes.forComplianceFields());

    this.browseDeploymentTargetsStore = new BrowseDeploymentTargetsStore(this);
    this.createDeploymentTargetStore = new CreateDeploymentTargetStore(this);

    this.browseImplementationGroupsStore = new BrowseImplementationGroupsStore(this);
    this.createImplementationGroupStore = new CreateImplementationGroupStore(this);
  }
}
